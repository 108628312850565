import * as objectPath from 'object-path'
import { trans, transChoice } from '@src/core/helpers/translate'
import useTasks from './tasks'

// eslint-disable-next-line complexity
export default function useLogs () {
  /**
   * logItemStyles
   * A list over styles for the log-items
   * - iconBgClass -> Bootstrap class - background color for the list-icon
   * - iconClass -> Bootstrap class - icon color
   * - icon -> FontAwesome icon-class
   */
  const logItemStyles = {
    missing: {
      iconBgClass: 'light-primary',
      iconClass: 'primary',
      icon: 'fal fa-question'
    },
    newtasknewrender: {
      icon: 'fad fa-tasks',
      iconClass: 'info',
      iconBgClass: 'light-info'
    },
    completedtasknewrender: {
      icon: 'far fa-check',
      iconClass: 'success',
      iconBgClass: 'light-success'
    },
    newtasknewtemplateimage: {
      icon: 'fad fa-tasks',
      iconClass: 'info',
      iconBgClass: 'light-info'
    },
    customersync: {
      icon: 'fal fa-sync',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    templatecreation: {
      icon: 'fal fa-layer-group',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    productsync: {
      icon: 'fal fa-sync',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    userlogin: {
      icon: 'fal fa-user-shield',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    completedtasktemplateimage: {
      icon: 'far fa-check',
      iconClass: 'success',
      iconBgClass: 'light-success'
    },
    styletypecreation: {
      icon: 'far fa-light-ceiling',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    styletypedeleted: {
      icon: 'far fa-trash',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    styletypeupdated: {
      icon: 'fal fa-sync',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    newtasknewstyletypeimage: {
      icon: 'fad fa-tasks',
      iconClass: 'info',
      iconBgClass: 'light-info'
    },
    deletedtaskstyletypeimage: {
      icon: 'fal fa-trash',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    templatedeleted: {
      icon: 'fal fa-trash',
      iconClass: 'primary',
      iconBgClass: 'light-primary'
    },
    completedtaskstyletypeimage: {
      icon: 'far fa-check',
      iconClass: 'success',
      iconBgClass: 'light-success'
    },
    newtasknewtemplatedesignerbg: {
      icon: 'fad fa-tasks',
      iconClass: 'info',
      iconBgClass: 'light-info'
    },
    completedtasknewtemplatedesignerbg: {
      icon: 'far fa-check',
      iconClass: 'success',
      iconBgClass: 'light-success'
    },
    taskskipped: {
      icon: 'far fa-angle-double-right',
      iconClass: 'success',
      iconBgClass: 'light-success'
    }
  }

  /**
   * getLogDetails()
   * - Returns a valid description based on provided data and translation-files
   * - Returns buttons with links and text if required
   */

  // eslint-disable-next-line complexity
  const getLogDetails = (data) => {
    // Custom descriptions varies from type to type
    let desc = ''
    let btn = false

    const { getTaskDetails } = useTasks()

    const logType = logItemStyles[objectPath.get(data, 'type', 'missing')] !== undefined
      ? objectPath.get(data, 'type', 'missing')
      : 'missing'

    if (logType === 'newtasknewrender') {
      // newtasknewrender -> New task : new render
      desc = trans('logs.index.items.labels.newtasknewrender.desc', {
        productname: objectPath.get(data, 'data.product.name', `(${transChoice('global.unknown', 0)})`),
        templatename: objectPath.get(data, 'data.template.name', `(${transChoice('global.unknown', 0)})`)
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('logs.index.items.labels.newtasknewrender.btn')
        }
      }
    } else if (logType === 'completedtasknewrender') {
      // completedtasknewrender -> Completed task: A product needs a render in a template / designer
      desc = trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.completedtasknewrender.unknownUser')), // eslint-disable-line max-len
        productname: objectPath.get(data, 'data.product.name', trans('logs.index.items.labels.completedtasknewrender.unknownProduct')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.completedtasknewrender.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.btn')
        }
      }
    } else if (logType === 'customersync') {
      // customersync -> Sync of customers
      desc = trans('logs.index.items.labels.customersync.desc', {
        count: objectPath.get(data, 'data.customers.count', trans('logs.index.items.labels.customersync.unknown'))
      })

      const logId = objectPath.get(data, 'id', false)
      if (logId !== false) {
        btn = {
          to: `/logs/customers/sync/${ logId }`,
          label: trans('logs.index.items.labels.customersync.btn')
        }
      }
    } else if (logType === 'templatecreation') {
      // templatecreation -> Template was created
      desc = trans('logs.index.items.labels.templatecreation.desc', {
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.templatecreation.unknown')), // eslint-disable-line max-len
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.templatecreation.unknown')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }`,
          label: trans('logs.index.items.labels.templatecreation.btn')
        }
      }
    } else if (logType === 'productsync') {
      // productsync -> Sync of products
      desc = trans('logs.index.items.labels.productsync.desc', {
        count: objectPath.get(data, 'data.products.count', trans('logs.index.items.labels.productsync.unknown')) // eslint-disable-line max-len
      })

      const logId = objectPath.get(data, 'id', false)
      if (logId !== false) {
        btn = {
          to: `/logs/products/sync/${ logId }`,
          label: trans('logs.index.items.labels.productsync.btn')
        }
      }
    } else if (logType === 'userlogin') {
      // userlogin -> A user logged in
      desc = trans('logs.index.items.labels.userlogin.desc', {
        username: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.userlogin.unknown')) // eslint-disable-line max-len
      })
    } else if (logType === 'newtasknewtemplateimage') {
      // newtasknewtemplateimage -> New task : New template image required (preview)
      desc = trans('logs.index.items.labels.newtasknewtemplateimage.desc', {
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.newtasknewtemplateimage.unknown')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'completedtasktemplateimage') {
      // completedtasktemplateimage -> Completed task : template image
      desc = trans('logs.index.items.labels.completedtasktemplateimage.desc', {
        user: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.completedtasktemplateimage.unknownUser')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.completedtasktemplateimage.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'newtasknewstyletypeimage') {
      // newtasknewstyletypeimage -> Completed task : template image
      desc = trans('logs.index.items.labels.newtasknewstyletypeimage.desc', {
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.newtasknewstyletypeimage.unknownStyletype')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.newtasknewstyletypeimage.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/stypetypes`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'completedtaskstyletypeimage') {
      // completedtaskstyletypeimage -> Completed task : styletype image
      desc = trans('logs.index.items.labels.completedtaskstyletypeimage.desc', {
        user: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.completedtasktemplateimage.unknownUser')), // eslint-disable-line max-len
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.completedtaskstyletypeimage.unknownStyletype')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.completedtaskstyletypeimage.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/stypetypes`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'styletypecreation') {
      // styletypecreation -> A new styletype was created
      desc = trans('logs.index.items.labels.styletypecreation.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.styletypecreation.unknown')), // eslint-disable-line max-len
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.styletypecreation.unknown')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.styletypecreation.unknown')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/stypetypes`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'styletypeupdated') {
      // styletypeupdated -> A styletype was updated (data and/or image)
      desc = trans('logs.index.items.labels.styletypeupdated.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.styletypeupdated.unknown')), // eslint-disable-line max-len
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.styletypeupdated.unknown')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.styletypeupdated.unknown')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/stypetypes`,
          label: trans('logs.index.items.labels.newtasknewtemplateimage.btn')
        }
      }
    } else if (logType === 'styletypedeleted') {
      // styletypedeleted -> A styletype was deleted
      desc = trans('logs.index.items.labels.styletypedeleted.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.styletypecreation.unknown')), // eslint-disable-line max-len
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.styletypecreation.unknown')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.styletypecreation.unknown')) // eslint-disable-line max-len
      })
    } else if (logType === 'deletedtaskstyletypeimage') {
      // deletedtaskstyletypeimage -> The task "Styletype image" was deleted (probably due to styletype deletion)
      desc = trans('logs.index.items.labels.deletedtaskstyletypeimage.desc', {
        styletypename: objectPath.get(data, 'data.styletype.name', trans('logs.index.items.labels.deletedtaskstyletypeimage.unknownStyletype')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.deletedtaskstyletypeimage.unknownTemplate')) // eslint-disable-line max-len
      })
    } else if (logType === 'templatedeleted') {
      // templatedeleted -> A template was deleted (sub-entries to log is added if required)
      desc = trans('logs.index.items.labels.templatedeleted.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.templatedeleted.unknownUser')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.templatedeleted.unknownTemplate')) // eslint-disable-line max-len
      })

      const logId = objectPath.get(data, 'id', false)
      if (logId !== false) {
        btn = {
          to: `/logs/templates/deleted/${ logId }`,
          label: trans('logs.index.items.labels.customersync.btn')
        }
      }
    } else if (logType === 'newtasknewtemplatedesignerbg') {
      // newtasknewtemplatedesignerbg -> New task : A designer in a template requires a background image
      desc = trans('logs.index.items.labels.newtasknewtemplatedesignerbg.desc', {
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.newtasknewtemplatedesignerbg.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('logs.index.items.labels.newtasknewtemplatedesignerbg.btn')
        }
      }
    } else if (logType === 'completedtasknewtemplatedesignerbg') {
      // completedtasknewtemplatedesignerbg -> New task : A product in a designer in a template requires a render
      desc = trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.unknownUser')), // eslint-disable-line max-len
        templatename: objectPath.get(data, 'data.template.name', trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.unknownTemplate')) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('logs.index.items.labels.completedtasknewtemplatedesignerbg.btn')
        }
      }
    } else if (logType === 'taskskipped') {
      // taskskipped -> Skipped a task : A task in the "oppgaver" list has been skipped

      const { desc: taskDesc } = getTaskDetails(objectPath.get(data, 'data.task', {}))

      desc = trans('logs.index.items.labels.taskskipped.desc', {
        authuser: objectPath.get(data, 'data.authUser.name', trans('logs.index.items.labels.taskskipped.unknownUser')), // eslint-disable-line max-len
        taskname: taskDesc
      })
    } else {
      desc = trans(`logs.index.items.labels.${ objectPath.get(data, 'type', 'missing') }.desc`)
    }

    return {
      desc,
      btn
    }
  }

  return {
    getLogDetails,
    logItemStyles
  }
}

<template>
  <div>
    <!--begin::Synchronize products-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('logs.index.title') }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('logs.index.desc') }}
            </span>
          </h2>
        </b-card-title>
      </div>
      <div class="card-body" style="min-height: 80px;">
        <!--begin::timeline-->
        <div class="timeline timeline-custom mt-3">
          <div
            v-for="(item, key) in items"
            :key="'item-' + key"
            class="timeline-item align-items-start"
          >
            <!--begin::label-->
            <div class="timeline-label">
              <p>
                {{ momentFullDate(item.createdOn) }}
              </p>
              <p>
                {{ transChoice('global.time', 1) }} {{ momentHoursMinutesSeconds(item.createdOn) }}
              </p>
            </div>
            <!--end::label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <div
                class="symbol symbol-40"
                :class="`symbol-${ item.iconBgClass }`"
              >
                <div class="symbol-label">
                  <i :class="`${ item.icon } text-${ item.iconClass }`"></i>
                </div>
              </div>
            </div>
            <!--end::Badge-->

            <!--begin::content-->
            <div class="timeline-content d-flex flex-column">
              <div
                class="timeline-content-inline"
              >
                <div
                  class="
                    d-flex
                    bg-light
                    rounded
                    p-2
                  "
                >
                  <!--begin::details-->
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <!--begin::title and description-->
                    <div class="px-2">
                      <p class="text-dark font-weight-bold mb-1">
                        {{ item.title }}
                      </p>
                      <!--end::title-->

                      <!--begin::description-->
                      <p class="font-size-sm text-dark-50 mb-0" v-html="item.desc"></p>
                      <!--end::description-->
                    </div>
                    <!--end::title and description-->

                    <!--begin::action-btn-->
                    <div v-if="item.btn" class="pr-2 flex-shrink-0">
                      <b-btn
                        :to="item.btn.to"
                        variant="light-primary"
                        size="sm"
                      >
                        {{ item.btn.label }}
                      </b-btn>
                    </div>
                    <!--end::action-btn-->
                  </div>
                  <!--end::details-->
                </div>
              </div>
            </div>
            <!--end::content-->
          </div>
        </div>
        <!--end::timeline-->
        <!--begin::more-btn-->
        <div class="d-flex justify-content-center align-items-center flex-wrap mt-8">
          <div class="d-flex align-items-center py-3">
            <div class="d-flex align-items-center">
              <Loader
                v-if="logProps.loading"
                :text="trans('logs.index.loading')"
                loader-class="relative"
                loader-style="margin-right: 80px;"
              ></Loader>
              <b-btn
                v-else-if="logProps.showMoreBtn"
                ref="logs_showmorebtn"
                variant="primary"
                class="py-4 px-6"
                size="sm"
                @click="loadMore(logProps.lastVisibleDoc)"
              >
                {{ trans('logs.index.btn.loadMore') }}
              </b-btn>
            </div>
          </div>
        </div>
        <!--end::more-btn-->
      </div>
    </b-card>
    <!--end::Synchronize products-->
  </div>
</template>


<script>
  import * as objectPath from 'object-path'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'

  import useDateTime from '@src/use/datetime'
  import useLogs from '@src/use/logs'
  // import { capitalize } from '@src/core/helpers/textUtils'

  export default {
    name: 'ProductsIndex',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('logs.index.title'),
            route: '/logs'
          }
        ])
      })

      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()
      const { logItemStyles, getLogDetails } = useLogs()

      const logProps = ref({
        limit: 50,
        loading: true,
        orderBy: 'createdOn',
        sortOrder: 'desc',
        lastVisibleDoc: true,
        showMoreBtn: true,
        type: false
      })

      const logEntries = ref([])

      const loadMore = async (paginateDoc) => {
        const btn = refs.logs_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getLogs = fb.functions.httpsCallable('backendLogsGetLogs')

        return await getLogs({
          data: {
            limit: logProps.value.limit,
            orderBy: logProps.value.orderBy, // disabled - TODO: Fix dates in backend
            sort: logProps.value.sortOrder,
            type: logProps.value.type,
            paginateDoc
          }
        }).then((res) => {
          if (logProps.value.loading) logEntries.value = []
          logProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= logProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            logEntries.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          logProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Old "created" Vue trigger:
      loadMore(logProps.value.lastVisibleDoc).then(() => {
        logProps.value.loading = false
      })

      // eslint-disable-next-line complexity
      const items = computed(() => logEntries.value.map((entry) => {
        const logType = logItemStyles[objectPath.get(entry, 'type', 'missing')] !== undefined
          ? objectPath.get(entry, 'type', 'missing')
          : 'missing'

        const { desc, btn } = getLogDetails(entry)

        return {
          createdOn: objectPath.get(entry, 'createdOn', {}),
          title: trans(`logs.index.items.labels.${ logType }.title`),
          desc,
          iconBgClass: logItemStyles[logType].iconBgClass,
          iconClass: logItemStyles[logType].iconClass,
          icon: logItemStyles[logType].icon,
          btn,
          variant: objectPath.get(entry, 'variant', 'info')
        }
      }))

      return {
        trans,
        transChoice,
        momentFullDate,
        momentHoursMinutesSeconds,
        // Log list specifics
        items,
        logProps,
        loadMore
      }
    }
  }
</script>

<style lang="scss">
  table {
    &#synclog {
      td {
        vertical-align: middle;
      }
    }
  }

  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
